export const navBarPermission = [
  {
    client: 'LOTTERY',
    clientName: 'LOTTERY',
    permission: 'ReadDashboard',
    title: 'Dashboard',
    link: '/lottery/dashboard',
    father: '',
    icon: '',
    isShowNav: true,
    needValidatePermission: true
  },
  {
    client: 'LOTTERY',
    clientName: 'LOTTERY',
    permission: 'ReadLotteryCycle' ,
    link: '/lottery/lottery-cycle',
    title: 'Lottery Cycle',
    father: '',
    icon: '',
    isShowNav: true,
    needValidatePermission: true
  },
  {
    client: 'LOTTERY',
    clientName: 'LOTTERY',
    permission: 'ReadGiveAwayTicket',
    link: '/lottery/giveaway-tickets',
    title: 'Giveaway Ticket',
    father: '',
    icon: '',
    isShowNav: true,
    needValidatePermission: true
  },
  {
    client: 'LOTTERY',
    clientName: 'LOTTERY',
    permission: 'ReadLotteryBaseConfig',
    link: '/lottery/base-lottery-cycle-config',
    title: 'Lottery Cycle Base Config',
    father: '',
    icon: '',
    isShowNav: true,
    needValidatePermission: true
  },
  {
    client: 'LOTTERY',
    clientName: 'LOTTERY',
    permission: 'WriteLotteryCycle',
    link: '/lottery/lottery-cycle/lottery-cycle-config',
    title: 'Lottery Cycle Config',
    father: '',
    icon: '',
    isShowNav: false,
    needValidatePermission: true
  },
  {
    client: 'SSO',
    clientName: 'SSO',
    permission: 'ReadClientManagement',
    title: 'Client Management',
    link: '/sso/client-management',
    father: '',
    icon: '',
    isShowNav: true,
    needValidatePermission: true
  },
  {
    client: 'SSO',
    clientName: 'SSO',
    permission: 'ReadUserManagement',
    title: 'User Management',
    link: '/sso/user-management',
    father: '',
    icon: '',
    isShowNav: true,
    needValidatePermission: true
  },
  {
    client: 'SSO',
    clientName: 'SSO',
    permission: 'ReadUserManagement',
    title: 'User Migration Management',
    link: '/sso/user-migration-management',
    father: '',
    icon: '',
    isShowNav: true,
    needValidatePermission: true
  },
  {
    client: 'ADMINPORTAL',
    clientName: 'ADMIN PORTAL',
    permission: 'ReadDashboard',
    title: 'Dashboard',
    link: '/portal/dashboard',
    father: '',
    icon: '',
    isShowNav: true,
    needValidatePermission: false
  },
  {
    client: 'ADMINPORTAL',
    clientName: 'ADMIN PORTAL',
    permission: 'ReadRoleManagement',
    title: 'Role Management',
    link: '/portal/role-management',
    father: '',
    icon: '',
    isShowNav: true,
    needValidatePermission: false
  },
  {
    client: 'ADMINPORTAL',
    clientName: 'ADMIN PORTAL',
    permission: 'ReadRoleManagement',
    title: 'Permission Management',
    link: '/portal/permission-management',
    father: '',
    icon: '',
    isShowNav: true,
    needValidatePermission: false
  },
  {
    client: 'SSO',
    clientName: 'SSO',
    permission: 'ReadJobManagement',
    title: 'Job Management',
    link: '/sso/job-management',
    father: '',
    icon: '',
    isShowNav: true,
    needValidatePermission: true
  },
  {
    client: 'SSO',
    clientName: 'SSO',
    permission: 'ReadDashboard',
    title: 'Edit Page',
    link: '/sso/user-management/edit',
    father: '',
    icon: '',
    isShowNav: false,
    needValidatePermission: true
  },
  {
    client: 'SSO',
    clientName: 'SSO',
    permission: 'ReadKycItemManagement',
    title: 'Kyc Management',
    link: '/sso/kyc-management',
    father: '',
    icon: '',
    isShowNav: true,
    needValidatePermission: true
  },
  {
    client: 'EVENTTICKET',
    clientName: 'EVENT TICKET',
    permission: 'ReadEventTicketManagement',
    title: 'Event Management',
    link: '/eventticket/event-management',
    father: '',
    icon: '',
    isShowNav: true,
    needValidatePermission: true
  },
  {
    client: 'EVENTTICKET',
    clientName: 'EVENT TICKET',
    permission: 'ReadEventTicketManagement',
    title: 'Bookings',
    link: '/eventticket/booking-management',
    father: '',
    icon: '',
    isShowNav: true,
    needValidatePermission: true
  },
  {
    client: 'EVENTTICKET',
    clientName: 'EVENT TICKET',
    permission: 'ReadEventTicketManagement',
    title: 'Check-in Ticket',
    link: '/eventticket/checkin-ticket',
    father: '',
    icon: '',
    isShowNav: true,
    needValidatePermission: true
  },
  {
    client: 'EVENTTICKET',
    clientName: 'EVENT TICKET',
    permission: 'ReadEventTicketManagement',
    title: 'Tickets',
    link: '/eventticket/ticket-behavior',
    father: '',
    icon: '',
    isShowNav: true,
    needValidatePermission: true
  },
]
