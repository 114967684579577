import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {BehaviorSubject, Observable, tap, of, map, switchMap, lastValueFrom} from "rxjs";
import {AssignRoleRequest, GetClientPermissionsResponse, GetRoleDetailsResponse, RolePermission, UpdateRoleClientPermission} from "./models";
import {LocalKeys} from "../../shared/enum";
import {FuseNavigationItem} from "../../../@fuse/components/navigation";
import {navBarPermission} from "./navBarPermission";
import { ActionEnum } from "app/shared/enum/action.enum";
import { ActionPermissions } from "./action-permission";

@Injectable({providedIn: 'root'})
export class PermissionService {

  permission$: BehaviorSubject<RolePermission[]> = new BehaviorSubject<RolePermission[]>(null);

  constructor(private httpClient: HttpClient) {
  }

  getMyPermission() {
    const url = `/v1/adminportal/roles/my`;
    return this.httpClient.get(url).pipe(tap((result: RolePermission[]) => {
      this.permission$.next(result);
      // this.setPermissions(result);
    }));
  }

  setPermissions(result: RolePermission[]) {
    localStorage.setItem(LocalKeys.PERMISSION, JSON.stringify(result));
  }

  getCurrentPermissions(): RolePermission[] {
    return JSON.parse(localStorage.getItem(LocalKeys.PERMISSION));
  }

  buildNavigation(): Observable<FuseNavigationItem[]> {
    return this.permission$.pipe(map((data: RolePermission[]) => {
        const result: FuseNavigationItem[] = [];
        if (data && data.length > 0) {
          data[0].permissionsGranted.sort((one, two) => (one.client < two.client ? -1 : 1));
          data[0].permissionsGranted.forEach(x => {
                if (x.permissions && x.permissions.length > 0 && x.client) {
                    const allMenu = navBarPermission.filter(n => x.client == n.client && n.isShowNav && x.permissions.indexOf(n.permission) > -1);
                    if (allMenu && allMenu.length) {
                        result.push(
                            {
                                id: x.client,
                                title: x.client,
                                type: 'group'
                            }
                        )
                        allMenu.forEach(a => {
                            result.push({
                                id: a.permission,
                                title: a.title,
                                type: 'basic',
                                link: a.link
                            })
                        })
                    }
                }
            });
        }
        return result;
      }))
  }

  generateNavigation(data: RolePermission[]){
    const result: FuseNavigationItem[] = [];
    if (data && data.length > 0) {
      data[0].permissionsGranted.sort((one, two) => (one.client < two.client ? -1 : 1));
      data[0].permissionsGranted.forEach(x => {
            if (x.permissions && x.permissions.length > 0 && x.client) {
                const allMenu = navBarPermission.filter(n => x.client == n.client && n.isShowNav && x.permissions.indexOf(n.permission) > -1);
                if (allMenu && allMenu.length) {
                  var childMenu: FuseNavigationItem[] = allMenu.map<FuseNavigationItem>(mn =>({
                      id: mn.permission,
                      title: mn.title,
                      type: 'basic',
                      link: mn.link
                  }));
                  result.push(
                      {
                          id: x.client,
                          title: allMenu[0].clientName,
                          type: 'collapsable',
                          children: childMenu
                      }
                  )

                }
            }
        });
    }

    return result;
  }



  canAccessOrShow(client: string, permissionText: string){
    const permission = this.permission$.getValue();
    if(permission && permission.length > 0){
      return !!permission[0].permissionsGranted.find(x => x.client == client && x.permissions.indexOf(permissionText) > -1) || false;
    }
    return false;
  }

  canAccess(routeStr: string){
    return this.getMyPermission().pipe(map(
        (permission: RolePermission[]) =>
      {
        const routeArr = routeStr.split('/');

        const routeCutAfter = routeStr.split('?');

        const requiredPermission = navBarPermission.find(x => routeCutAfter[0] == x.link);

        return !requiredPermission?.needValidatePermission ||
          (!!permission[0]?.permissionsGranted.find(x => x.client.toUpperCase() == routeArr[1].toUpperCase()
          && x.permissions.indexOf(requiredPermission.permission) > -1) || false);
      }
    ));
  }

  getAllPermissions(){
    const url = `/v1/adminportal/roles/permissions`;
    return this.httpClient.get(url);
  }

  getRoles(pageSize: number, pageNumber: number){
    const url = `/v1/adminportal/roles`;

    const httpParams = new HttpParams()
      .append('pageSize', pageSize)
      .append('pageIndex', pageNumber);

    return this.httpClient.get(url, { params: httpParams});
  }

  assignRoles(body: AssignRoleRequest){
      const url = `/v1/adminportal/roles/assign`;
      return this.httpClient.post(url, body);
  }

  getRoleDetails(roleId: string): Observable<GetRoleDetailsResponse> {
    const url = `/v1/adminportal/roles/${roleId}`;

    return this.httpClient.get<GetRoleDetailsResponse>(url);
  }

  getSSOClients(pageSize: number, pageNumber: number){
    const url = `/v1/sso/clients`;

    const httpParams = new HttpParams()
      .append('pageSize', pageSize)
      .append('pageIndex', pageNumber);

    return this.httpClient.get(url, { params: httpParams});
  }

  getAllClientPermission(): Observable<GetClientPermissionsResponse[]>{
    const url = `/v1/adminportal/roles/all-client-permissions`;

    return this.httpClient.get<GetClientPermissionsResponse[]>(url);
  }

  getClientPermission(pageSize: number, pageNumber: number, clientName: string){
    const url = `/v1/adminportal/roles/client-permissions`;

    let httpParams = new HttpParams()
      .append('pageSize', pageSize)
      .append('pageIndex', pageNumber);

    if(clientName && clientName != ''){
      httpParams = httpParams.append('client', clientName);
    }

    return this.httpClient.get(url, { params: httpParams});
  }

  updateRoleClientPermissions(roleId: string, body:UpdateRoleClientPermission){
    const url = `/v1/adminportal/roles/${roleId}`;

    return this.httpClient.put(url, body);
  }

  getRequiredPermission(action: ActionEnum, client: string) {
    return ActionPermissions.find(x => x.client == client && x.action == action)?.requiredPermission;
  }

  isValidPermission(action: ActionEnum, client: string): Observable<boolean>{
    return this.permission$.pipe(
      map((data: RolePermission[]) => {
        if(data){
          const requiredPermission = this.getRequiredPermission(action, client);

          return data.some(x => x.permissionsGranted.some(x => x.client == client && x.permissions.includes(requiredPermission.toString())));
        }
        return false;
      }),
    );
  }

  createNewUserRolePermission(body:UpdateRoleClientPermission){
    const url = `/v1/adminportal/roles`;

    return this.httpClient.post(url, body);
  }
}
