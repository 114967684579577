import { ActionEnum } from "app/shared/enum/action.enum";
import { PermissionEnum } from "app/shared/enum/permission.enum";

export const ActionPermissions = [
    {
        client: 'LOTTERY',
        requiredPermission: PermissionEnum.WriteLotteryWinnerConfig,
        action: ActionEnum.LOTTERY_UPDATE_WINNER_CONFIG
    },
    {
        client: 'LOTTERY',
        requiredPermission: PermissionEnum.WriteLotteryWinnerConfig,
        action: ActionEnum.LOTTERY_END_LOTTERY_CYCLE
    },
    {
        client: 'LOTTERY',
        requiredPermission: PermissionEnum.WriteLotteryBaseConfig,
        action: ActionEnum.LOTTERY_UPDATE_BASE_CONFIG
    },
    {
        client: 'LOTTERY',
        requiredPermission: PermissionEnum.WriteLotteryTicket,
        action: ActionEnum.LOTTERY_GIVE_AWAY_TICKET
    }
]